<template>
  <v-dialog persistent v-model="dialog" height="auto">
    <div class="card card-frame">
      <div v-if="loading" style="height: 182px">
        <div
          class="text-center d-flex align-items-center justify-content-center h-100"
        >
          <img
            width="10"
            class="img-fluid"
            :src="imageLoading"
            style="width: 80px; height: 50px"
          />
        </div>
      </div>
      <div v-else>
        <div class="card-header">
          <span class="text-h5">Detail PayOut Transaksi</span>
        </div>
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="row">
                  <label
                    for="refNo"
                    class="col-4 col-form-label font-weight-normal"
                    >Transaction ID</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.refNo || '-'"
                    />
                  </div>
                </div>
                <div class="row">
                  <label
                    for="source_refno"
                    class="col-4 col-form-label font-weight-normal"
                    >Merchant Reference No.</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.source_refno || '-'"
                    />
                  </div>
                </div>
                <div class="row">
                  <label
                    for="pg_trxid"
                    class="col-4 col-form-label font-weight-normal"
                    >Issuer Reference No.</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.pg_trxid || '-'"
                    />
                  </div>
                </div>
                <div class="row">
                  <label
                    for="source_refno"
                    class="col-4 col-form-label font-weight-normal"
                    >Transaction Date</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.trx_datetime || '-'"
                    />
                  </div>
                </div>
                <div class="row">
                  <label
                    for="pg_id_desc"
                    class="col-4 col-form-label font-weight-normal"
                    >Issuer
                  </label>
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.pg_id_desc || '-'"
                    />
                  </div>
                </div>
                <div class="row">
                  <label
                    for="product_name"
                    class="col-4 col-form-label font-weight-normal"
                    >Channel</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.product_name || '-'"
                    />
                  </div>
                </div>
                <div class="row">
                  <label
                    for="customerId"
                    class="col-4 col-form-label font-weight-normal"
                    >Beneficiary Number
                  </label>
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.customerId || '-'"
                    />
                  </div>
                </div>
                <div class="row">
                  <label
                    for="customer_name"
                    class="col-4 col-form-label font-weight-normal"
                    >Beneficiary Name
                  </label>
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.customer_name || '-'"
                    />
                  </div>
                </div>
                <div class="row">
                  <label
                    for="amount"
                    class="col-4 col-form-label font-weight-normal"
                    >Transaction Amount
                  </label>
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.amount"
                    />
                  </div>
                </div>
                <div class="row">
                  <label
                    for="netamount"
                    class="col-4 col-form-label font-weight-normal"
                    >Deduction Amount
                  </label>
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.netamount"
                    />
                  </div>
                </div>
                <div class="row align-items-center">
                  <label
                    for="sts_desc"
                    class="col-4 col-form-label font-weight-normal"
                    >Status
                  </label>
                  <div class="col-7">
                    <span
                      v-if="data.sts_desc == 'SUCCESS'"
                      class="badge bg-success text-white text-bold"
                      >{{ data.sts_desc }}</span
                    >
                    <span
                      v-else-if="data.sts_desc == 'PROCESSING'"
                      class="badge bg-error text-white text-bold"
                      >{{ data.sts_desc }}</span
                    >
                    <span
                      v-else-if="data.sts_desc == 'FAILED'"
                      class="badge bg-error text-white text-bold"
                      >{{ data.sts_desc }}</span
                    >
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="row">
                  <label
                    for="failed_remarks"
                    class="col-4 col-form-label font-weight-normal"
                    >Remarks
                  </label>
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.failed_remarks || '-'"
                    />
                  </div>
                </div>
                <div class="row align-items-center">
                  <label
                    for="is_settled"
                    class="col-4 col-form-label font-weight-normal"
                    >Settled
                  </label>
                  <div class="col-7">
                    <span
                      v-if="data.is_settled == 1"
                      class="badge bg-success text-bold"
                      >Yes</span
                    >
                    <span v-else class="badge bg-gray-300 text-black text-bold"
                      >No</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div
                  class="row"
                  v-if="['admin', 'superadmin'].includes(data.roleName)"
                >
                  <label
                    for="supplier_ffee"
                    class="col-4 col-form-label font-weight-normal"
                    >Issuer Fixed Fee</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.supplier_ffee"
                    />
                  </div>
                </div>
                <div
                  class="row"
                  v-if="['admin', 'superadmin'].includes(data.roleName)"
                >
                  <label
                    for="supplier_sfee"
                    class="col-4 col-form-label font-weight-normal"
                    >Issuer Security Fee</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.supplier_sfee"
                    />
                  </div>
                </div>
                <div
                  class="row"
                  v-if="['admin', 'superadmin'].includes(data.roleName)"
                >
                  <label
                    for="referral_ffee"
                    class="col-4 col-form-label font-weight-normal"
                    >Referral Fixed Fee</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.referral_ffee"
                    />
                  </div>
                </div>
                <div
                  class="row"
                  v-if="['admin', 'superadmin'].includes(data.roleName)"
                >
                  <label
                    for="referral_sfee"
                    class="col-4 col-form-label font-weight-normal"
                    >Referral Security Fee</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.referral_sfee"
                    />
                  </div>
                </div>
                <div
                  class="row"
                  v-if="['admin', 'superadmin'].includes(data.roleName)"
                >
                  <label
                    for="toppay_ffee"
                    class="col-4 col-form-label font-weight-normal"
                    >Paybender Fixed Fee</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.toppay_ffee"
                    />
                  </div>
                </div>
                <div
                  class="row"
                  v-if="['admin', 'superadmin'].includes(data.roleName)"
                >
                  <label
                    for="toppay_sfee"
                    class="col-4 col-form-label font-weight-normal"
                    >Paybender Security Fee</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.toppay_sfee"
                    />
                  </div>
                </div>
                <div class="row" v-if="data.roleName == 'referral'">
                  <label
                    for="referralFee"
                    class="col-4 col-form-label font-weight-normal"
                    >Referral Amount</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.referralFee"
                    />
                  </div>
                </div>
                <div class="row" v-if="data.roleName == 'referral'">
                  <label
                    for="toppayFee"
                    class="col-4 col-form-label font-weight-normal"
                    >Fee Amount</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.toppayFee"
                    />
                  </div>
                </div>
                <div class="row" v-if="data.roleName == 'merchant'">
                  <label
                    for="feeAmount"
                    class="col-4 col-form-label font-weight-normal"
                    >Fee Amount</label
                  >
                  <div class="col-7">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext font-weight-bold"
                      :value="data.feeAmount"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="row mt-2 text-center">
            <div class="col-12 col-md-12">
              <button
                @click="this.$emit('closeDialog')"
                class="btn btn-block btn-info mb-0 mr-0"
                :disabled="loading"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalPayoutDetailsTrx",
  props: {
    roleId: Number,
    openDialog: Boolean,
    data: Object,
    loading: Boolean,
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    dialog: false,
  }),
  emits: ["closeDialog"],
  watch: {
    openDialog: function (newVal) {
      this.dialog = newVal;
    },
    data: function (newVal) {
      console.log("newval", newVal);
    },
  },
};
</script>
